<template>
  <div>
    <b-alert
      :variant="alertData.variant"
      :show="dismissCountDown"
      dismissible
      fade
      class="mb-1"
      @dismiss-count-down="countDownChanged"
    >
      <div class="alert-body">
        <span><strong>{{ alertData.strongText }}</strong> {{ alertData.lightText }}</span>
      </div>
    </b-alert>
    <b-overlay :show="saving">
      <validation-observer
        ref="form"
        #default="{invalid}"
      >
        <form @submit.prevent="onSubmit">
          <b-card
            footer-class="text-muted"
            body-class="p-0"
          >
            <b-tabs
              v-model="tabIndex"
              pills
              nav-class="mb-3 pt-2 pr-2 pl-2"
            >
              <b-tab
                button-id="project"
              >
                <div slot="title">
                  <feather-icon
                    class="mr-1"
                    icon="AlertCircleIcon"
                  /> {{ $t('projectUpsertForm.project.title') }}
                </div>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <image-input
                      v-model="form.pictureUrl"
                      :upload-label="$t('projectUpsertForm.project.pictureUrl')"
                      :size-label="$t('projectUpsertForm.project.pictureUrlPhotosize')"
                      :instructions-label="$t('projectUpsertForm.project.pictureUrlInstructions')"
                      :show-delete-button="false"
                      :max-size="(2 ** 25)"
                      @change="(value) => { form.pictureUrl = value }"
                    />
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.project.name')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.project.name')"
                        vid="name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.name"
                          name="name"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.project.namePlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.project.titular')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.project.titular')"
                        vid="titular"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.titular"
                          name="titular"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.project.titularPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.project.supervisor')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.project.supervisor')"
                        vid="supervisor"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.supervisor"
                          name="supervisor"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.project.supervisorPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.project.technician')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.project.technician')"
                        vid="technician"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.technician"
                          name="technician"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.project.technicianPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.project.hsiev')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.project.hsiev')"
                        vid="hsiev"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.hsiev"
                          step="any"
                          type="number"
                          name="hsiev"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.project.hsievPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.project.tvida')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.project.tvida')"
                        vid="tvida"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.tvida"
                          step="any"
                          type="number"
                          name="tvida"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.project.tvidaPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab
                button-id="installation"
              >
                <div slot="title">
                  <feather-icon
                    class="mr-1"
                    icon="MapIcon"
                  /> {{ $t('projectUpsertForm.installation.title') }}
                </div>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.installation.name')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.installation.name')"
                        vid="Installation.name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.Installation.name"
                          name="Installation.name"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.installation.namePlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.installation.cnpj')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.installation.cnpj')"
                        vid="Installation.cnpj"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.Installation.cnpj"
                          name="Installation.cnpj"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.installation.cnpjPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.installation.cep')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.installation.cep')"
                        vid="Installation.cep"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.Installation.cep"
                          name="Installation.cep"
                          debounce="1000"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.installation.cepPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.installation.address')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.installation.address')"
                        vid="Installation.address"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.Installation.address"
                          name="Installation.address"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.installation.addressPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.installation.city')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.installation.city')"
                        vid="Installation.city"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.Installation.city"
                          name="Installation.city"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.installation.cityPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('projectUpsertForm.installation.matr')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('projectUpsertForm.installation.matr')"
                        vid="Installation.matr"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.Installation.matr"
                          name="Installation.matr"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :placeholder="$t('projectUpsertForm.installation.matrPlaceholder')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab
                button-id="rooms"
              >
                <div slot="title">
                  <feather-icon
                    class="mr-1"
                    icon="ImageIcon"
                  /> {{ $t('projectUpsertForm.rooms.title') }}
                </div>
                <hr style="width: 100%">
                <div>
                  <h3 class="ml-2 mb-2 mt-1">
                    {{ $t('projectUpsertForm.rooms.registerTitle') }}
                  </h3>
                  <p class="ml-2 mb-2 mt-1">
                    {{ $t('projectUpsertForm.rooms.registerDescription') }}
                  </p>
                </div>
                <div
                  v-for="(room, i) in Rooms"
                  :key="room.id"
                  class="pb-1 px-2"
                  style="margin-left: 0; margin-right: 0;"
                >
                  <hr
                    v-if="i > 0"
                    style="width: 100%"
                  >
                  <b-row
                    class="pb-1"
                    style="margin-left: 0; margin-right: 0;"
                  >
                    <b-col
                      sm="6"
                      md="6"
                      lg="6"
                      class="pl-2"
                    >
                      <image-input
                        v-model="room.xPictureUrl"
                        :upload-label="$t('projectUpsertForm.rooms.xPictureUrl')"
                        :size-label="$t('projectUpsertForm.rooms.xPictureUrlPhotosize')"
                        :instructions-label="$t('projectUpsertForm.rooms.xPictureUrlInstructions')"
                        :show-delete-button="false"
                        :max-size="(2 ** 25)"
                        @change="(value) => onChangePicture(value, room, 'xPictureUrl')"
                      />
                    </b-col>
                  </b-row>
                  <b-row
                    class="pb-1"
                    style="margin-left: 0; margin-right: 0;"
                  >
                    <b-col
                      sm="6"
                      md="6"
                      lg="6"
                      class="pl-2"
                    >
                      <image-input
                        v-model="room.yPictureUrl"
                        :upload-label="$t('projectUpsertForm.rooms.yPictureUrl')"
                        :size-label="$t('projectUpsertForm.rooms.yPictureUrlPhotosize')"
                        :instructions-label="$t('projectUpsertForm.rooms.yPictureUrlInstructions')"
                        :show-delete-button="false"
                        :max-size="(2 ** 25)"
                        @change="(value) => onChangePicture(value, room, 'yPictureUrl')"
                      />
                    </b-col>
                  </b-row>
                  <b-row
                    class="pb-1"
                    style="margin-left: 0; margin-right: 0;"
                  >
                    <b-col
                      sm="6"
                      md="6"
                      lg="6"
                      class="pl-2"
                    >
                      <image-input
                        v-model="room.zPictureUrl"
                        :upload-label="$t('projectUpsertForm.rooms.zPictureUrl')"
                        :size-label="$t('projectUpsertForm.rooms.zPictureUrlPhotosize')"
                        :instructions-label="$t('projectUpsertForm.rooms.zPictureUrlInstructions')"
                        :show-delete-button="false"
                        :max-size="(2 ** 25)"
                        @change="(value) => onChangePicture(value, room, 'zPictureUrl')"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.name')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.name')"
                        >
                          <b-form-input
                            v-model="room.name"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.namePlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.pdsal')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.pdsal')"
                        >
                          <b-form-input
                            v-model="room.pdsal"
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.pdsalPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.pdlab')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.pdlab')"
                        >
                          <b-form-input
                            v-model="room.pdlab"
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.pdlabPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.CalcParamId')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.CalcParamId')"
                        >
                          <calc-param-select
                            v-if="calcParams.length"
                            v-model="room.CalcParamId"
                            :calc-params="calcParams"
                            :placeholder="$t('projectUpsertForm.rooms.CalcParamIdPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <p>{{ $t('projectUpsertForm.rooms.WorkLoad.title') }}</p>
                  <b-row>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.WorkLoad.pcdia')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.WorkLoad.pcdia')"
                        >
                          <b-form-input
                            v-model="room.WorkLoad.pcdia"
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.WorkLoad.pcdiaPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.WorkLoad.campc')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.WorkLoad.campc')"
                        >
                          <b-form-input
                            v-model="room.WorkLoad.campc"
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.WorkLoad.campcPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.WorkLoad.dscam')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.WorkLoad.dscam')"
                        >
                          <b-form-input
                            v-model="room.WorkLoad.dscam"
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.WorkLoad.dscamPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.WorkLoad.days')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.WorkLoad.days')"
                        >
                          <b-form-input
                            v-model="room.WorkLoad.days"
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.WorkLoad.daysPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.WorkLoad.wfisi')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.WorkLoad.wfisi')"
                        >
                          <b-form-input
                            v-model="room.WorkLoad.wfisi"
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.WorkLoad.wfisiPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.WorkLoad.pimrt')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.WorkLoad.pimrt')"
                        >
                          <b-form-input
                            v-model="room.WorkLoad.pimrt"
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.WorkLoad.pimrtPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-if="i >= 0"
                      cols="12"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.WorkLoad.cimrt')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.WorkLoad.cimrt')"
                        >
                          <b-form-input
                            v-model="room.WorkLoad.cimrt"
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.WorkLoad.cimrtPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <p>{{ $t('projectUpsertForm.rooms.WorkLoad.calculated') }}</p>
                  <b-row>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.WorkLoad.wprim')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.WorkLoad.wprim')"
                        >
                          <b-form-input
                            v-model="room.WorkLoad.wprim"
                            readonly
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.WorkLoad.wprimPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="i >= 0"
                      cols="6"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.WorkLoad.wimrt')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.WorkLoad.wimrt')"
                        >
                          <b-form-input
                            v-model="room.WorkLoad.wimrt"
                            readonly
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.WorkLoad.wimrtPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-if="i >= 0"
                      cols="12"
                      class="px-2"
                    >
                      <b-form-group :label="$t('projectUpsertForm.rooms.WorkLoad.wneut')">
                        <validation-provider
                          #default="{ errors }"
                          class="flex-description-row"
                          :name="$t('projectUpsertForm.rooms.WorkLoad.wneut')"
                        >
                          <b-form-input
                            v-model="room.WorkLoad.wneut"
                            readonly
                            step="any"
                            type="number"
                            :class="errors.length > 0 ? 'is-invalid':null"
                            :placeholder="$t('projectUpsertForm.rooms.WorkLoad.wneutPlaceholder')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <app-collapse>
                    <app-collapse-item :title="$t('projectUpsertForm.rooms.Blindages.viewMore')">
                      <div
                        v-for="(blindage, blindageIndex) in room.Blindages"
                        :key="`blindage-${blindage.id}`"
                        class="pb-1 px-2"
                        style="margin-left: 0; margin-right: 0;"
                      >
                        <hr
                          v-if="blindageIndex > 0"
                          style="width: 100%"
                        >
                        <b-row>
                          <b-col
                            v-if="blindageIndex >= 0"
                            cols="12"
                            class="px-2"
                          >
                            <b-form-group :label="$t('projectUpsertForm.rooms.Blindages.name')">
                              <validation-provider
                                #default="{ errors }"
                                class="flex-description-row"
                                :name="$t('projectUpsertForm.rooms.Blindages.name')"
                              >
                                <b-form-input
                                  v-model="blindage.name"
                                  :class="errors.length > 0 ? 'is-invalid':null"
                                  :placeholder="$t('projectUpsertForm.rooms.Blindages.namePlaceholder')"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col
                            v-if="blindageIndex >= 0"
                            cols="6"
                            class="px-2"
                          >
                            <b-form-group :label="$t('projectUpsertForm.rooms.Blindages.point')">
                              <validation-provider
                                #default="{ errors }"
                                class="flex-description-row"
                                :name="$t('projectUpsertForm.rooms.Blindages.point')"
                              >
                                <b-form-input
                                  v-model="blindage.point"
                                  :class="errors.length > 0 ? 'is-invalid':null"
                                  :placeholder="$t('projectUpsertForm.rooms.Blindages.pointPlaceholder')"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col
                            v-if="blindageIndex >= 0"
                            cols="6"
                            class="px-2"
                          >
                            <b-form-group :label="$t('projectUpsertForm.rooms.Blindages.BarrierId')">
                              <validation-provider
                                #default="{ errors }"
                                class="flex-description-row"
                                :name="$t('projectUpsertForm.rooms.Blindages.BarrierId')"
                              >
                                <barrier-select
                                  v-if="barriers.length"
                                  v-model="blindage.BarrierId"
                                  :barriers="barriers"
                                  :placeholder="$t('projectUpsertForm.rooms.Blindages.BarrierIdPlaceholder')"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <feather-icon
                          v-if="blindageIndex >= 0"
                          class="ml-2"
                          icon="Trash2Icon"
                          size="24"
                          @click="deleteBlindage(room, blindage.id)"
                        />
                      </div>

                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="ml-2 mb-2 mt-1"
                        :disabled="saving"
                        @click="addRoomBlindage(room)"
                      >
                        {{ $t('projectUpsertForm.rooms.Blindages.addMore') }}
                      </b-button>
                    </app-collapse-item>
                  </app-collapse>
                  <feather-icon
                    v-if="i >= 0"
                    class="ml-2"
                    icon="Trash2Icon"
                    size="24"
                    @click="deleteRoom(room.id)"
                  />
                </div>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="ml-2 mb-2 mt-1"
                  :disabled="saving"
                  @click="addProjectRoom()"
                >
                  {{ $t('projectUpsertForm.rooms.addMoreRooms') }}
                </b-button>

              </b-tab>
            </b-tabs>

            <div
              slot="footer"
              style="display: flex; justify-content: space-between;"
            >
              <div>
                <b-button
                  id="save-form"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="!hasValidArrays || invalid || saving"
                >
                  <b-spinner
                    v-if="saving"
                    small
                  />
                  {{ saving ? $t('projectUpsertForm.saving') : $t('projectUpsertForm.save') }}
                </b-button>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  :disabled="saving"
                  @click.prevent="goToList"
                >
                  {{ $t('projectUpsertForm.cancel') }}
                </b-button>
              </div>
            </div>
          </b-card>
        </form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BTabs, BTab, BCard, BFormGroup, BFormInput, BRow,
  BCol, BButton, BSpinner, BOverlay, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BarrierSelect from '@/views/components/BarrierSelect.vue'
import CalcParamSelect from '@/views/components/CalcParamSelect.vue'
import ImageInput from '@/views/components/ImageInput.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// We need this to make vue rendering smoother for non-saved data
function tempId(model) {
  return `tmpid-${model}-${new Date().getTime()}`
}

function removeTempIdFromObject(obj) {
  const cloned = { ...obj }

  if (obj.id && typeof obj.id === 'string' && obj.id.startsWith('tmpid-')) {
    delete cloned.id
  }

  return cloned
}

const emptyRoom = () => ({
  id: tempId('Room'),
  CalcParamId: null,
  name: '',
  pdlab: 0,
  pdsal: 0,
  xPictureUrl: null,
  yPictureUrl: null,
  zPictureUrl: null,
  // Carga de trabalho
  WorkLoad: {
    pcdia: 80,
    campc: 4,
    dscam: 0.65,
    days: 5,
    wfisi: 60,
    pimrt: 70, // Porcentagem (até 100)
    cimrt: 5,
    // Calculados via api
    wprim: null,
    wimrt: null,
    wneut: null,
  },
  Blindages: [],
})

const emptyBlindage = () => ({
  id: tempId('Blindage'),
  BarrierId: null,
  name: '',
  point: '',
})

export default {
  name: 'ProjectUpsertForm',

  components: {
    BTabs,
    BTab,
    BCard,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BAlert,
    BarrierSelect,
    CalcParamSelect,
    ImageInput,
    AppCollapse,
    AppCollapseItem,
  },
  directives: { Ripple },

  data() {
    return {
      barriers: [],
      calcParams: [],
      // Alert
      dismissSecs: 4,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      alertData: {
        lightText: null,
        strongText: null,
        variant: 'danger',
      },

      tabIndex: 0,
      saving: false,
      form: {
        hsiev: 10000,
        tvida: 1000,
        pictureUrl: '',
        name: '',
        supervisor: '',
        technician: '',
        titular: '',
        Installation: {
          name: '',
          address: '',
          cep: '',
          city: '',
          cnpj: '',
          matr: '',
        },
      },
      Rooms: [],
      required,
    }
  },
  computed: { hasValidArrays() { return this.Rooms.length } },
  watch: { 'form.Installation.cep': 'getCep' },
  async created() { await this.load() },
  methods: {
    ...mapActions('project', {
      loadProject: 'getupsert',
      upsertProject: 'upsert',
    }),
    async getCep(addressCode) {
      if (!addressCode) return
      try {
        const data = await this.$store.dispatch('address/search', { addressCode })
        this.form.Installation.city = data.city
        this.form.Installation.address = `${data.street} ${data.neighborhood}`
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }
    },
    onChangePicture(pictureUrl, room, field) {
      // eslint-disable-next-line
      room[field] = pictureUrl
    },
    deleteBlindage(room, blindageId) {
      // eslint-disable-next-line
      room.Blindages = room.Blindages.filter(e => e.id !== blindageId)
    },
    addRoomBlindage(room) { room.Blindages.push(emptyBlindage()) },
    goToList() { this.$router.push({ name: 'pages-project' }) },
    async load() {
      this.calcParams = await this.$store.dispatch('calcParam/index')
      this.barriers = await this.$store.dispatch('barrier/index')
      await Promise.all([this.loadProjectForRoute()])
    },
    showAlert(alertProps, variant) {
      this.alertData = alertProps
      this.alertData.variant = variant
      this.dismissCountDown = this.dismissSecs
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    async loadProjectForRoute() {
      const { id } = this.$route.params
      if (id === 'new') return

      const project = await this.loadProject(id)
      this.Rooms = project.Rooms
      const keysToDelete = ['deletedAt', 'createdAt', 'updatedAt', 'Rooms']
      keysToDelete.forEach(key => { delete project[key] })
      this.form = project
    },
    deleteRoom(id) { this.Rooms = this.Rooms.filter(e => e.id !== id) },
    async onSubmit() {
      const success = await this.$refs.form.validate()
      if (!success) { return }

      const roomsToSend = this.Rooms.map(removeTempIdFromObject)
      for (let count = 0; count < roomsToSend.length; count += 1) {
        roomsToSend[count].Blindages = roomsToSend[count].Blindages.map(removeTempIdFromObject)
      }
      const project = { ...this.form, Rooms: roomsToSend }

      this.saving = true
      try {
        const updated = await this.upsertProject(project)
        this.showAlert(this.$t('projectUpsertForm.methods.onSubmit.alert'), 'success')
        if (updated.id !== Number(this.$route.params.id)) {
          this.$router.push({ name: 'pages-project-upsert', params: { id: updated.id } })
          await this.loadProjectForRoute()
          return
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            ...this.$t('projectUpsertForm.upsertdErrorToast'),
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      } finally { this.saving = false }
    },

    addProjectRoom() { this.Rooms.push(emptyRoom()) },
  },
}
</script>

<style>
  @media (max-width: 767px){
    .nav-pills{
      display: block;
    }
    .small-devices-display{
      display: block;
    }
  }
  .flex-description-row {
    display: flex;
    align-items: center;
    align-content: center;
  }
</style>
