<template>
  <b-media no-body>
    <b-media-aside>
      <b-link>
        <b-avatar
          ref="previewEl"
          variant="info"
          circle
          :src="currentPicture ? apiDataUri(currentPicture) : null"
          size="80"
          :rounded="rounded"
        >
          <FeatherIcon
            v-if="!currentPicture"
            icon="ImageIcon"
            size="35"
            class="text-white"
          />
        </b-avatar>
      </b-link>
    </b-media-aside>

    <b-media-body>
      <div class="d-flex">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-75"
          style="margin-right: 10px; flex: 1; max-width: 244px"
          @click="$refs.refInputEl.$el.click()"
        >
          <feather-icon
            icon="PlusCircleIcon"
          />
          {{ uploadLabel }}
        </b-button>

        <b-button
          v-if="showDeleteButton && !withIcon"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          block
          class="mb-75"
          style="flex: 1"
          @click="change(null)"
        >
          {{ deleteLabel }}
        </b-button>
        <feather-icon
          v-if="showDeleteButton && withIcon"
          style="{ background-color: #red; margin-top: 1%; }"
          size="25"
          icon="Trash2Icon"
          color="danger"
          @click="change(null)"
        />
      </div>

      <div>
        <div class="font-small-2">
          {{ sizeLabel }}
        </div>
        <div class="font-small-2">
          {{ instructionsLabel }}
        </div>
        <div class="font-small-2">
          {{ instructions }}
        </div>
      </div>
    </b-media-body>

    <b-form-file
      ref="refInputEl"
      v-model="profileFile"
      :accept="accepts"
      :hidden="true"
      plain
      @input="onChangePicture"
    />
  </b-media>
</template>

<script>
import axios from '@axios'
import {
  BFormFile, BButton, BMedia, BMediaAside, BMediaBody, BLink, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BAvatar,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    withIcon: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, String],
      default: null,
    },
    accepts: {
      type: String,
      default: '.jpg, .jpeg, .png, .gif',
    },
    uploadLabel: {
      type: String,
      default: '',
    },
    deleteLabel: {
      type: String,
      default: '',
    },
    sizeLabel: {
      type: String,
      default: '',
    },
    instructionsLabel: {
      type: String,
      default: '',
    },
    instructions: {
      type: String,
      default: '',
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 2 ** 25,
    },
  },
  data() {
    return {
      currentPicture: this.value,
      profileFile: null,
    }
  },
  computed: { apiDataUri() { return uri => `${process.env.VUE_APP_API_URL}/files/${uri}` } },
  watch: {
    value(val) {
      this.currentPicture = val
    },
  },
  methods: {
    change(url) {
      this.currentPicture = url
      this.$emit('change', url)
    },

    async onChangePicture(file) {
      if (!file) { return }
      if (file.size > this.maxSize) {
        this.$swal({
          // todo
          ...this.$t('accountSettings.failedToSave'),
          text: this.$t('accountSettings.avatarTooLarge'),
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

        return
      }
      const payload = new FormData()
      payload.append('files', file, file.fileName)

      try {
        const { data } = await axios({
          method: 'POST',
          url: '/files',
          data: payload,
        })
        this.change(data.url)
      } catch (err) {
        this.$swal({
          ...this.$t('accountSettings.failedToSave'),
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
  },
}
</script>
